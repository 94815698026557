import { lazy, Suspense } from "react";

const BoardGames = () => {
    const BetaVersion = lazy(() => import("Views/BetaVersion"));
    const Carousel = lazy(() =>
        import("components/BoardGames/Carousel/Carousel")
    );
    const Games = lazy(() => import("components/BoardGames/Games/Games"));

    return (
        <Suspense>
            <BetaVersion>
                <Carousel id='companies' />
                <Games />
            </BetaVersion>
        </Suspense>
    );
};

export default BoardGames;
