import { lazy, Suspense } from "react";

const FaqPage = () => {
    const BetaVersion = lazy(() => import("Views/BetaVersion"));
    const FaqSection = lazy(() => import("components/FAQS/FAQS"));

    return (
        <Suspense>
            <BetaVersion>
                <FaqSection />
            </BetaVersion>
        </Suspense>
    );
};

export default FaqPage;
