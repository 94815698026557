/* eslint-disable sonarjs/no-gratuitous-expressions */
/* eslint-disable sonarjs/cognitive-complexity */
import ImageExtractor from "utils/Extractors/images";
import config from "main-config.json";
import { ENavLayouts } from "constants/Navigation";

/**
 * @param {*} el
 * @exports anchors function in the Navigation.jsx component
 */

export const defaultLayout = config.runtime.pages.components.navpanel.layout;

export const anchors = (el) => {
    const elementId = el.replace(/[/#]/g, "");
    const elementExists = document.getElementById(elementId);
    if (elementExists && window.location.pathname === "/") {
        elementExists.scrollIntoView({ behavior: "smooth" });
    } else if (window.location.pathname !== "/" && !elementExists) {
        setTimeout(() => {
            if (el.startsWith("/#")) {
                window.location.pathname = "/";
            }
        }, 800);
    }
};

export function closeNavMenu() {
    const navbarResponsive = document.getElementById("navbarResponsive");

    navbarResponsive.classList.remove("show");
}

export const toggleLangDropdown = () => {
    const dropdownMenu = document.querySelector(".custom-dropdown-menu");
    dropdownMenu.classList.toggle("show");
};

export function isPortrait() {
    return window.orientation === 0 || window.orientation === 180;
}

export const langImages = (lngs) =>
    ImageExtractor(`default/components/flags/flag_${lngs}.svg`);

export const storeLang = (switchLang) => {
    const storedLang = localStorage.getItem("language");
    if (storedLang && config.supported_langs.includes(storedLang)) {
        switchLang(storedLang);
    }
};

export const getNavigationStyle = (defaultLayout, innerWidth, scroll) => {
    let backgroundColor = "black";
    let height = `${innerWidth >= 599 ? "105px" : "80px"}`;

    if (defaultLayout === ENavLayouts.HORIZONTAL) {
        if (innerWidth >= 992) {
            backgroundColor = scroll > 50 ? "black" : "transparent";
        }
    } else {
        if (innerWidth >= 992) {
            backgroundColor = scroll > 50 ? "black" : "transparent";
        } else {
            backgroundColor = "black";
        }
    }

    if (scroll > 50) {
        height = `${innerWidth >= 599 ? "95px" : "80px"}`;
    }

    return {
        backgroundColor,
        height,
    };
};

export function closeVerticalMenu() {
    const menuToggle = document.getElementById("menu-toggle");
    const menuButton = document.querySelector(".menu-button");
    const menuButtonBefore = document.querySelector(".menu-button::before");
    const menuButtonAfter = document.querySelector(".menu-button::after");
    menuToggle.checked = false;
    menuButton.classList.remove("active");
    const menuButtonStyle = window.getComputedStyle(menuButton);
    if (menuButtonStyle.getPropertyValue("transform") !== "none") {
        menuButtonBefore.style.transform = "none";
        menuButtonAfter.style.transform = "none";
    }
}

export const isHorizontal = () => {
    return defaultLayout == "Horizontal";
};

export const useNavbarCollapse = (isNavOpen, isClosing) => {
    if (isNavOpen) {
        return " show animated-nav";
    } else if (isClosing) {
        return " closing-menu";
    } else {
        return " custom-collapsed";
    }
};
