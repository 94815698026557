import BetaVersion from "Views/BetaVersion";
import "./BirthdayPartyPage.css";
import config from "main-config.json";
import { lazy, Suspense } from "react";

const BirthdayPartyPage = () => {
    const BirthdayPartyPageWithBalloons = lazy(() =>
        import("./BirhdayPartyPageWithBalloons")
    );
    const BirthdayPartyPageWithImage = lazy(() =>
        import("./BirhdayPartyPageWithImage")
    );
    return (
        <Suspense>
            {config.birthday_page_layout === "withImage" && (
                <BirthdayPartyPageWithImage />
            )}
            {config.birthday_page_layout === "withBalloons" && (
                <BirthdayPartyPageWithBalloons />
            )}
            <BetaVersion />
        </Suspense>
    );
};

export default BirthdayPartyPage;
