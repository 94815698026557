import config from "main-config.json";
import { mapDualStoreScenarios } from "helpers/Scenarios.helpers";
import { lazy, Suspense } from "react";
const HomeCarousel = lazy(() => import("components/HomeCarousel/HomeCarousel"));
const Scenarios = lazy(() => import("components/Homepage/Scenarios/Scenarios"));
const Map = lazy(() => import("components/Homepage/Map/Map"));
const Counter = lazy(() => import("components/Homepage/Counter/Counter"));
const About = lazy(() => import("components/Homepage/About/About"));
const ContactButtons = lazy(() =>
    import("components/ContactButtons/ContactButtons.jsx")
);
const CookiesContainer = lazy(() =>
    import("components/Homepage/CookiesContainer/Cookies")
);
const Slicker = lazy(() => import("components/Slicker/Slicker"));
const BetaVersion = lazy(() => import("Views/BetaVersion"));
const Popup = lazy(() => import("components/Popup/Popup"));

export const Home = () => {
    const { header, titles } = config.runtime.pages.components.about_us;
    const hasPopup = config.popupImg ? true : false;
    const hasContactButtons = config.hasContactButtons ? true : false;

    return (
        <Suspense>
            {hasPopup && <Popup />}
            {config.nearbyStores && <Slicker />}
            <HomeCarousel />
            <Scenarios data={mapDualStoreScenarios()} />
            <Map />
            <Counter />
            <About
                header={header}
                titles={titles}
                linkId='learn-more-btn'
                className='about-us-title'
                ImageId='book_now_imgr'
            />
            {hasContactButtons && <ContactButtons />}
            <CookiesContainer />
            <BetaVersion />
        </Suspense>
    );
};
export default Home;
