import PropTypes from "prop-types";
import config from "main-config.json";
import { ELanguageDropdown } from "constants/LanguageDropdown";

const LanguageDropdown = ({ content, textStyle, imageStyle, src }) => {
    if (config.supported_langs_content == ELanguageDropdown.Text) {
        return (
            <text
                style={textStyle}
                className={`${config.stores[0].name}-text-lang-dropdown`}
                role='button'>
                {content}
            </text>
        );
    } else if (config.supported_langs_content == ELanguageDropdown.Image) {
        return (
            <img
                src={src}
                className='image-lang-dropdown'
                role='button'
                style={imageStyle}
            />
        );
    }
};

LanguageDropdown.propTypes = {
    content: PropTypes.any,
    textStyle: PropTypes.object,
    imageStyle: PropTypes.object,
    src: PropTypes.string,
};

export default LanguageDropdown;
