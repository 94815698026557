import { Link } from "react-router-dom";
import { anchors } from "helpers/Navigation.helpers";
import config from "main-config.json";
import { useTranslation } from "react-i18next";
import DesktopLangDropdown from "./LanguageDropdown/DesktopDropdown";
import MobileLangDropdown from "./LanguageDropdown/MobileDropdown";
import PropTypes from "prop-types";

export const { navpanel } = config.runtime.pages.components;

const Links = ({ closeNavMenu }) => {
    const { t } = useTranslation();

    const handleLinkClick = (itemName) => {
        closeNavMenu();
        anchors(itemName);
    };

    return (
        <ul
            className='navbar-nav ml-lg-auto'
            id='links-list'>
            {/* Links */}
            {navpanel.links.map((item, index) => (
                <li
                    key={index}
                    className='nav-item'
                    style={{ paddingTop: "1%" }}>
                    <Link
                        style={{ paddingBottom: "1%" }}
                        id='nav-links'
                        className='nav-link js-scroll-trigger'
                        onClick={() => handleLinkClick(item.link)}
                        to={item.link}>
                        {t(item.name)}
                    </Link>
                </li>
            ))}
            <li>
                {window.innerWidth >= 992 ? (
                    <DesktopLangDropdown />
                ) : (
                    <MobileLangDropdown closeMenu={closeNavMenu} />
                )}
            </li>
        </ul>
    );
};

Links.propTypes = {
    closeNavMenu: PropTypes.func,
};

export default Links;
