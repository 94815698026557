import i18next from "i18next";
import config from "main-config.json";
import { initReactI18next } from "react-i18next";
import English_Translation from "./en/en.json";
import Arabic_Translation from "./ar/ar.json";
import Russian_Translation from "./ru/ru.json";
import French_Translation from "./fr/fr.json";
import Saudi_Arabia_Translation from "./sa/sa.json";
import Spanish_Translation from "./es/es.json";
import Italian_Translation from "./it/it.json";

let Translator;

i18next
    .use(initReactI18next)
    .init({
        fallbackLng: "en",
        debug: false,
        supportedLngs: config.supported_langs,
        react: {
            bindI18n: "languageChanged",
            bindI18nStore: "",
            transEmptyNodeValue: "",
            transSupportBasicHtmlNodes: true,
        },
        resources: {
            en: {
                translation: English_Translation,
            },
            ar: {
                translation: Arabic_Translation,
            },
            ru: {
                translation: Russian_Translation,
            },
            fr: {
                translation: French_Translation,
            },
            sa: {
                translation: Saudi_Arabia_Translation,
            },
            es: {
                translation: Spanish_Translation,
            },
            it: {
                translation: Italian_Translation,
            },
        },
    })
    .then((t) => (Translator = t));
let lang = localStorage.getItem("language");
if (lang) {
    i18next.changeLanguage(lang);
} else {
    i18next.changeLanguage(config.supported_langs[0]);
    localStorage.setItem("language", config.supported_langs[0]);
}

export default Translator;
