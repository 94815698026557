import { lazy, Suspense } from "react";
const BetaVersion = lazy(() => import("Views/BetaVersion"));
const TeamBuilding = lazy(() => import("components/TeamBuilding/TeamBuilding"));

const TeamBuildingPage = () => {
    return (
        <Suspense>
            <BetaVersion>
                <TeamBuilding />
            </BetaVersion>
        </Suspense>
    );
};

export default TeamBuildingPage;
