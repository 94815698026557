import { useState, useEffect } from "react";
import Links from "../NavigationLinks";
import { useNavbarCollapse } from "helpers/Navigation.helpers";
import "./index.css";

/**
 * @returns the Horizontal navigation menu
 */
const HorizontalNavigation = () => {
    const [isNavOpen, setIsNavOpen] = useState(false);
    const [isClosing, setIsClosing] = useState(false);

    useEffect(() => {
        if (isNavOpen) {
            setIsClosing(false);
        }
    }, [isNavOpen]);

    const toggleNav = () => {
        setIsNavOpen(!isNavOpen);
        if (isNavOpen) {
            setIsClosing(true);
            setTimeout(() => {
                setIsClosing(false);
            }, 490);
        }
    };
    const navbarCollapse = useNavbarCollapse(isNavOpen, isClosing);

    return (
        <>
            <button
                onClick={toggleNav}
                id='toggling-btn'
                className={`navbar-toggler navbar-toggler-right ${
                    isNavOpen ? "active" : ""
                }`}
                type='button'
                aria-expanded={isNavOpen ? "true" : "false"}
                aria-label='Toggle navigation'>
                <i className='fa fa-bars fa-lg'></i>
            </button>
            <div
                className={`navbar-collapse ${navbarCollapse}`}
                id='navbarResponsive'>
                <Links closeNavMenu={() => setIsNavOpen(false)} />
            </div>
        </>
    );
};

export default HorizontalNavigation;
