import Social from "components/Homepage/Social/Social";
import Footer from "components/Footer/Footer";
import Navigation from "components/Navigation/Navigation";
import PropTypes from "prop-types";

export const BetaVersion = (props) => {
    return (
        <>
            <Navigation />
            {props.children}
            <Social />
            <Footer />
        </>
    );
};

BetaVersion.propTypes = {
    children: PropTypes.node,
};

export default BetaVersion;
