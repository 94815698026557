import { lazy, Suspense } from "react";

const EventsPage = () => {
    const BetaVersion = lazy(() => import("Views/BetaVersion"));
    const Events = lazy(() => import("components/Events/Descriptions"));
    const Balloons = lazy(() => import("components/Events/Balloons/Balloons"));
    return (
        <Suspense>
            <BetaVersion>
                <Balloons />
                <Events />
            </BetaVersion>
        </Suspense>
    );
};

export default EventsPage;
